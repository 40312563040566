import React, { useEffect, useState } from "react";

const HarivishnuCalc = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState("");

  const correctPassword = "hhhh"; // 🔒 You can still change this

  useEffect(() => {
    const savedAuth = localStorage.getItem("harivishnu-auth");
    if (savedAuth === "true") {
      setAuthenticated(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      localStorage.setItem("harivishnu-auth", "true");
      setAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  if (authenticated) {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTfOWaRC3E0UgnPd5Ts62jT_EtKTTKI-7QlcJetXKalUvEdz0AZgir8oP8PXCHzPn0sV4pJMH_cPy8F/pubhtml?gid=969452296&amp;single=true&amp;widget=true&amp;headers=false"
          title="Protected Google Sheet"
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} style={{ padding: "2rem", textAlign: "center" }}>
      <h2>Enter Password to View</h2>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        style={{ padding: "0.5rem", fontSize: "1rem" }}
      />
      <button type="submit" style={{ marginLeft: "1rem", padding: "0.5rem 1rem" }}>
        Submit
      </button>
    </form>
  );
};

export default HarivishnuCalc;