import React, { useState, useRef, useEffect } from 'react';
import * as Realm from "realm-web";
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';

import './App.css';
import logo from './logo.png';
import stella from './stella.svg';
import getUserLocation from './utils/getUserLocation';
import CustomOrderOffsite from './components/CustomOrderOffsite';
import countryToCurrency from './assets/countryToCurrency.json';
import HarivishnuCalc from './components/HarivishnuCalc';

const mongoURI = process.env.REACT_APP_MONGOURI;

const Home = () => {
  const ContactForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (event) => setName(event.target.value);
    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleMessageChange = (event) => setMessage(event.target.value);

    const submitForm = (event) => {
      event.preventDefault();
      setIsLoading(true);
      const app = new Realm.App({ id: "application-0-vnzqc" });
      const credentials = Realm.Credentials.anonymous();

      app.logIn(credentials).then(user => {
        const mongodb = user.mongoClient("mongodb-atlas");
        const collection = mongodb.db("webapp").collection("visitors");
        return collection.insertOne({ name, email, message });
      })
        .then(() => {
          setIsSubmitted(true);
          setIsLoading(false);
        })
        .catch(err => {
          console.error("Failed to submit form:", err);
          setIsLoading(false);
        });
    };

    return (
      <section id="contact">
        <h2>Get in Touch</h2>
        <p>Reach out to discuss how our marketing solutions can grow your business.</p>
        {!isSubmitted ? (
          <form onSubmit={submitForm}>
            <input type="text" placeholder="Your Name" required value={name} onChange={handleNameChange} disabled={isLoading} />
            <input type="email" placeholder="Your Email" required value={email} onChange={handleEmailChange} disabled={isLoading} />
            <textarea placeholder="Tell us how we can help" required value={message} onChange={handleMessageChange} disabled={isLoading}></textarea>
            <button type="submit" disabled={isLoading}>Submit</button>
          </form>
        ) : (
          <div className="thankYouMessage">Thank you! We'll be in touch soon.</div>
        )}
      </section>
    );
  };

  const [isNavVisible, setIsNavVisible] = useState(false);
  const stories = [
    {
      title: "Social Media Marketing Success Story",
      headline: "Turning Followers into Revenue Streams",
      description: "Our strategic marketing campaigns increased online sales by 300% within the first quarter.",
      img: stella,
      url: "https://www.instagram.com/stellamccartney/?hl=en"
    },
    {
      title: "SEO Optimization Success Story",
      headline: "Boosting Organic Traffic by 200%",
      description: "By implementing a custom SEO strategy, our client saw a dramatic increase in search engine rankings and organic traffic.",
      img: stella,
      url: "#"
    },
    {
      title: "Content Marketing Success Story",
      headline: "Engaging Audiences with Compelling Content",
      description: "Our content strategy helped a brand establish authority in its niche, leading to higher engagement and customer retention.",
      img: stella,
      url: "#"
    }
  ];

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button className="hamburger" onClick={() => setIsNavVisible(!isNavVisible)}>
          &#9776;
        </button>
        <nav className={`${isNavVisible ? "nav-visible" : ""} navigation`}>
          <a href="#services">Services</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a>
        </nav>
      </header>

      <main>
        <section id="hero" className="hero-section">
          <h1>Welcome to Gignance Marketing</h1>
          <p>Your partner in strategic and digital marketing solutions.</p>
        </section>

        <section id="services" className="services-section">
          <h2>Our Services</h2>
          <div className="services-container">
            <div className="service-item">
              <h3>Social Media Marketing</h3>
              <p>Expand your reach and boost engagement with our proven strategies.</p>
            </div>
            <div className="service-item">
              <h3>Content Marketing</h3>
              <p>Craft compelling content to attract and retain your audience.</p>
            </div>
            <div className="service-item">
              <h3>SEO & PPC</h3>
              <p>Enhance your search visibility and drive traffic to your business.</p>
            </div>
          </div>
        </section>

        <section id="about">
          <h2>About Us</h2>
          <p>Gignance is a marketing agency dedicated to helping brands grow through innovative and data-driven marketing strategies.</p>
        </section>

        <section id="testimonials" className="testimonials">
          <h2>Success Stories</h2>
          <div className="stories-container">
            {stories.map((story, index) => (
              <div key={index} className="story">
                <h3>{story.title}</h3>
                {/* <img src={story.img} alt="Story" /> */}
                <h4>{story.headline}</h4>
                <p>{story.description}</p>
                <a href={story.url} target="_blank" rel="noopener noreferrer" className="story-link">Check here</a>
              </div>
            ))}
          </div>
        </section>

        <ContactForm />
      </main>

      <footer>
        <h3>Gignance Marketing</h3>
        <span><b>mail@Gignance.com</b></span><br />
        <span>+64225401142</span><br />
        <span>222 Waimea Road, Nelson, New Zealand, 7011</span>
      </footer>
    </div>
  );
};

function App() {

  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState(null);
  const getCountry = () => {
    axios.get(`${mongoURI}geolocation`)
      .then((response) => {
        const countryCode = response.data.countryCode;
        const userCurrency = countryToCurrency[countryCode];  // Map country to currency
        setCountry(countryCode);
        setCurrency(userCurrency || 'USD');  // Fallback to USD only if userCurrency is not available
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
        setCurrency('USD');  // Fallback if API call fails
      });
  };

  useEffect(() => {
    getCountry();
  }, []);

  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path="/promotion/:currency" element={<CurrencyRedirect />} /> */}
          {/* Define other routes as needed */}
          <Route path="/marketing"
            element={
              <CustomOrderOffsite
                currency={currency}
              />
            }
          />
          <Route path="/harivishnu"
            element={
              <HarivishnuCalc
              />
            }
          />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
